<template>
  <v-dialog v-model="dialogVisibility" max-width="600" persistent>
    <v-card>
      <v-card-title class="headline">
        <v-icon class="mr-2" large :color="typeCfg.color">{{typeCfg.icon}}</v-icon>
        <span v-text="dialogCfg.heading" />
      </v-card-title>
      
      <v-card-text v-html="dialogCfg.msg" />

      <v-card-actions>
        <v-spacer />
        <template v-for="(action, i) in dialogCfg.actions">
          <v-btn
            small
            :key="`modalAction_${i}`"
            :color="action.color"
            :text="action.isFlat"
            @click="fireAction(action.method)"
            :dark="!action.isFlat"
            :class="`elevation-0 ${!action.isFlat?'black--text':''}`"
          >
            {{action.text}}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
 // how to call from UI: 
 /*

 this.confirm({
  heading: 'Dialog',
  msg: 'This is a dialog',
  type:'error / success / info / warning',
  actions: [
    {
      color: 'error / success / info / warning',
      isFlat: true, 
      text: 'My action',
      method: () => {
        console.log('action clicked')
      }
    }
  ]
})

*/
  export default {
    data(){
      return {
        visi: true
      }
    },
    computed:{
      typeCfg(){
  
        let type = this.dialogCfg.type;

        let cfg = {
          color: 'info',
          icon: 'mdi-information-outline'
        }
 
        if(type === 'success'){
          cfg = {
            color: 'success',
            icon: 'mdi-check-circle-outline'
          }
        }
        if(type === 'warning'){
          cfg = {
            color: 'warning',
            icon: 'mdi-alert-circle-outline'
          }
        }
        if(type === 'error'){
          cfg = {
            color: 'error',
            icon: 'mdi-alert-circle-outline'
          }
        }
        return cfg;
      },
      dialogVisibility:{
        get(){
          return this.$store.getters['uiMod/getConfirmModalVisibility']
        },
        set(v){
          this.$store.dispatch('uiMod/setConfirmModalVisibility', v)
        }
      },
      dialogCfg(){
        return this.$store.getters['uiMod/getConfirmModalCfg']
      }
    },
    methods: {
      fireAction(action){
        if(action) {
          action();
        }
        this.dialogVisibility = false;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>